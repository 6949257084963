html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

.logo {
  width: 150px;
  margin-bottom: 20px;
  align-self: center;
}



.base {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: stretch;
  height: 100%;
}

.baseformulario {
  background-color: #efefef;
  flex: 2;
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  padding-right: 10px;
  justify-content: center;
  justify-items: center;
  align-content: center;
}

.basefraseepica {
  background-color: #fff;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 30px;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
}

.fraseepica {
  font-weight: 200;
  font-size: 30px;
}

.tituloform {
  font-size: 60px;
  font-weight: bold;
}

.subtituloform {
  font-weight: 200;
  font-size: 20px;
}

* {
  box-sizing: border-box;
}

@media (max-width: 800px) {
  .base {
    flex-direction: column;
    height: auto;
  }

  .baseformulario {
    padding: 5px;
    align-items: center;
  }

  .basefraseepica {
    width: auto;
  }

  .fraseepica {
    font-size: 20px;
  }

  .logo {
    width: 80px;
  }

  .tituloform {
    font-size: 40px;
  }

  .subtituloform{
    font-size: 15px;
  }


}