@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;700&display=swap);
html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

.logo {
  width: 150px;
  margin-bottom: 20px;
  -webkit-align-self: center;
          align-self: center;
}



.base {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
  -webkit-align-items: stretch;
          align-items: stretch;
  height: 100%;
}

.baseformulario {
  background-color: #efefef;
  -webkit-flex: 2 1;
          flex: 2 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-left: 40px;
  padding-right: 10px;
  -webkit-justify-content: center;
          justify-content: center;
  justify-items: center;
  -webkit-align-content: center;
          align-content: center;
}

.basefraseepica {
  background-color: #fff;
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 30px;
  -webkit-justify-content: center;
          justify-content: center;
  justify-items: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-content: center;
          align-content: center;
}

.fraseepica {
  font-weight: 200;
  font-size: 30px;
}

.tituloform {
  font-size: 60px;
  font-weight: bold;
}

.subtituloform {
  font-weight: 200;
  font-size: 20px;
}

* {
  box-sizing: border-box;
}

@media (max-width: 800px) {
  .base {
    -webkit-flex-direction: column;
            flex-direction: column;
    height: auto;
  }

  .baseformulario {
    padding: 5px;
    -webkit-align-items: center;
            align-items: center;
  }

  .basefraseepica {
    width: auto;
  }

  .fraseepica {
    font-size: 20px;
  }

  .logo {
    width: 80px;
  }

  .tituloform {
    font-size: 40px;
  }

  .subtituloform{
    font-size: 15px;
  }


}
body {
  font-family: 'Poppins', sans-serif !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}



form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 20px
}

form label {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  max-width: 400px;
  margin-top: 10px;
  font-weight: 200;

}

.tag-error {
  background: #ffd4d4;
  padding: 10px;
  max-width: 500px;
  margin-top: 30px;
  margin-bottom: -10px;
}

form input,
select {
  height: 50px;
  border-radius: 6px;
  margin-top: 5px;
  padding-left: 5px;
  outline: none;
  font-size: 15px;
  font-weight: 200;
  border: 1px solid rgb(206, 206, 206);
  font-family: 'Poppins', sans-serif !important;
}

form [type="file"i] {
  border: none
}

form button {
  max-width: 400px;
  font-size: 20px;
  cursor: pointer;
  padding: 10px
}

.App-link {
  color: #61dafb;
}


@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
